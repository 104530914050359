import { Injectable } from '@angular/core';
import { take } from 'rxjs';
import { PlanEnum, User } from '../models/user';
import { PaymentDialogComponent } from '../components/dialogs/plans-and-payments/payment-dialog/payment-dialog.component';
import { FeatureService } from './feature-service.service';
import { ProfileService } from './show/profile.service';
import { MatDialog } from '@angular/material/dialog';
import { AnalyticsNotifierService, AnalyticsNotifierServices } from './utils/analytics-notifier.service';

type PaymentDialogOpenedFrom = 'project-planning' | 'studio' | 'user-settings' | 'edit-room' | 'sidenav';

@Injectable({
    providedIn: 'root',
})
export class PaymentDialogService {
    private isPaymentDialogOpen: boolean;
    user: User;

    constructor(
        private featureService: FeatureService,
        private profileService: ProfileService,
        private dialog: MatDialog,
        private analyticsNotifierService: AnalyticsNotifierService
    ) {
        this.profileService.user$.subscribe({
            next: (user) => {
                this.user = user;
            },
        });
    }

    public openPaymentDialogAsync(toDisableClose: boolean, openedFrom: PaymentDialogOpenedFrom) {
        if (this.isPaymentDialogOpen) {
            return;
        }

        return new Promise<boolean>((resolve, reject) => {
            this.featureService
                .hasFeatureAccess('AB_mustpay_free')
                .pipe(take(1))
                .subscribe({
                    next: (hasAccess) => {
                        const shouldShowPaymentsDialog = this.user.plan === PlanEnum.FREE && hasAccess === true;

                        if (shouldShowPaymentsDialog && !this.isPaymentDialogOpen) {
                            this.isPaymentDialogOpen = true;

                            const dialogRef = this.dialog.open(PaymentDialogComponent, {
                                disableClose: toDisableClose,
                                panelClass: 'payments-dialog',
                                maxHeight: '90vh',
                                data: {
                                    subscriptionFrom: openedFrom,
                                },
                            });

                            dialogRef.afterClosed().subscribe((result: boolean) => {
                                this.isPaymentDialogOpen = false; // Reset the flag when dialog closes

                                // Nofify HERE
                                if (result) {
                                    //Successful payment
                                    this.analyticsNotifierService.notifyEvent(
                                        'Subscription created',
                                        { from: openedFrom },
                                        [AnalyticsNotifierServices.GTM]
                                    );
                                }
                                /// Check if the dialog was closed by paying or by clicking on close dialog (result = null)
                                return resolve(result);
                            });
                        } else {
                            this.isPaymentDialogOpen = false;
                            return resolve(true);
                        }
                    },
                    error: (error) => {
                        console.warn(`NO FEATURE FLAG DATA`);
                        this.isPaymentDialogOpen = false;
                        return resolve(false);
                    },
                });
        });
    }
}
