import { Injectable, AfterViewInit } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { ConfigurationService } from '../configuration.service';
import mixpanel from 'mixpanel-browser';
import { FacebookPixelService } from './facebook-pixel.service';
import { User } from 'src/app/models/user';
import { InAppNotificationService } from 'src/app/pages/private/host/services/in-app-notification.service';
import { filter, take } from 'rxjs';
import LogRocket from 'logrocket';
import { HubspotProxyService, INoteReq } from './hubspot-proxy.service';
// import { ProfileService } from '../show/profile.service';
import { sha256 } from 'js-sha256';

export enum KEY_EVENT_TYPE {
    TRIAL = 'Trial Start',
    STARTED_ONBOARDING = 'Started Onboarding',
}

export enum AnalyticsNotifierServices {
    GTM = 'GTM',
    MIXPANEL = 'Mixpanel',
    FACEBOOK = 'Facebook',
}

export enum FACEBOOK_EVENT_NAMES {
    StartTrial = 'StartTrial',
    Purchase = 'Purchase',
    Lead = 'Lead',
    CompleteRegistration = 'CompleteRegistration',
    Subscribe = 'Subscribe',
}

@Injectable({
    providedIn: 'root',
})
export class AnalyticsNotifierService {
    identified = false;
    user: User;

    constructor(
        private gtmService: GoogleTagManagerService,
        private config: ConfigurationService,
        private facebookPixelService: FacebookPixelService,
        private hostNotifications: InAppNotificationService,
        private configService: ConfigurationService
        // private profileService: ProfileService
    ) {}

    pushTag(eventDetails: { event: string; data?: any }) {
        this.notifyEvent(eventDetails.event, eventDetails.data);
    }

    notifyEvent(
        eventName: string,
        data: any = null,
        sendTo: AnalyticsNotifierServices[] = [AnalyticsNotifierServices.GTM, AnalyticsNotifierServices.MIXPANEL]
    ) {
        if (!this.config.trackUserEvents) return;

        if (this.user?.email) {
            const note: INoteReq = {
                email: this.user.email,
                event: eventName,
            };

            data = { ...data, hashedEmail: sha256(this.user.email) }; // Hash the email

            // try {
            //   this.hubspotProxyService.pushHubspotNote(note).subscribe((res) => {
            //     if (!res) return;
            //   });
            // } catch (error) {
            //   console.warn(error, 'could not send to hubspot');
            //   return;
            // }
        }
        // Gooogle Tag Manager
        if (sendTo.includes(AnalyticsNotifierServices.GTM)) {
            this.gtmService.pushTag({
                event: eventName,
                data: data,
            });
        }

        // Mixpanel
        if (sendTo.includes(AnalyticsNotifierServices.MIXPANEL)) {
            mixpanel.track(eventName, data);
        }
    }

    notifyPage(pagePath: string) {
        try {
            // console.log('Sending page path to tracking', pagePath);

            // Only notify in production
            if (this.config.trackUserEvents) {
                // We will strip the name of the page - eg HOST

                // Google tag manager
                let gtmTag = {
                    event: 'page',
                    pageName: pagePath,
                    pageTitle: pagePath,
                    pagePath: pagePath,
                    title: pagePath,
                    data: {
                        pageName: pagePath,
                        hashedEmail: this.user?.email ? sha256(this.user.email) : null,
                    },
                };

                this.gtmService.pushTag(gtmTag);

                // Mixpanel
                mixpanel.track(`${pagePath} - (Page Viewed)`, { page: pagePath });

                // Facebook
                this.facebookPixelService.trackCustomEvent('PageView');
            }
        } catch (e) {
            console.error(e);
        }
    }

    notifyKeyEvent(
        user: User,
        type: KEY_EVENT_TYPE,
        extraParams: {
            plan?: string;
            price?: number;
            transactionValue?: number;
        } = {}
    ) {
        if (!this.config.trackUserEvents) return;
        if (!user) return;

        /// GOOGLE TAG MANAGER
        const analyticsData = {
            userEmail: user?.email,
            date: new Date().toISOString(),
            ...extraParams,
        };
        this.pushTag({
            event: type.toString(),
            data: analyticsData,
        });
        // END OF GOOGLE TAG MANAGER

        // FACEBOOK
        const facebookData = {
            userId: user?._id,
            date: new Date().toISOString(),
            ...extraParams,
        };
        let facebookEvent: FACEBOOK_EVENT_NAMES;
        if (type === KEY_EVENT_TYPE.STARTED_ONBOARDING) {
            facebookEvent = FACEBOOK_EVENT_NAMES.CompleteRegistration;
        }
        if (type === KEY_EVENT_TYPE.TRIAL) {
            facebookEvent = FACEBOOK_EVENT_NAMES.StartTrial;
        }
        this.facebookPixelService.trackCustomEvent(facebookEvent, facebookData);
        // END OF FACEBOOK

        // MIXPANEL
        mixpanel.track(type.toString(), extraParams);
        // END OF MIXPANEL
    }

    initalize() {
        if (this.configService.trackUserEvents) {
            mixpanel.init(this.configService.mixpanelToken, {
                debug: !this.configService.isProduction,
            });
            LogRocket.init('6ucmou/shuffll');
            LogRocket.getSessionURL(function (sessionURL) {
                mixpanel.track('LogRocket', { sessionURL: sessionURL });
            });
            this.hostNotifications.setMixpanelInitalize(true);
        }
    }

    identify(user: User) {
        this.user = user;
        if (this.config.trackUserEvents && !this.identified) {
            this.hostNotifications.mixPanelIntialized$
                .pipe(
                    filter((isInitalized) => isInitalized),
                    take(1)
                )
                .subscribe((isInitalized) => {
                    if (!isInitalized) return;

                    mixpanel.identify(user.email);

                    // Mixpanel
                    mixpanel.people.set({
                        $name: user.name,
                        $email: user.email,
                        domain: user.branding?.company.domain ?? user.branding?.company.name,
                    });

                    // Logrocket
                    LogRocket.identify(user.email, {
                        name: user.name,
                        email: user.email,
                    });

                    /// hostNotifications.mixPanelIdentified$ to true
                    this.hostNotifications.setMixpanelIdentified(true);
                    this.identified = true;
                });

            // if (user.email) {
            //   const note: INoteReq = {
            //     email: user.email,
            //     event: 'identify',
            //   };
            //   this.hubspotProxyService.pushHubspotNote(note);
            // }
        }
    }
}
