import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { PlansService } from '../../../../services/plans.service';
import { MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { IAppliedCoupon, IProductAndPayment } from 'src/app/services/api/plans-api.service';
import { SnackBarService } from 'src/app/services/utils/snack-bar.service';
import { AnalyticsNotifierService } from '../../../../services/utils/analytics-notifier.service';

@Component({
    selector: 'payment-dialog',
    templateUrl: './payment-dialog.component.html',
    styleUrl: './payment-dialog.component.scss',
})
export class PaymentDialogComponent implements OnInit, OnDestroy {
    onDestroy$ = new Subject<boolean>();
    unifiedProduct: IProductAndPayment;
    appliedCoupon: IAppliedCoupon;
    finishedLoadingCoupon$ = new Subject<void>();
    constructor(
        public plansService: PlansService,
        public dialogRef: MatDialogRef<PaymentDialogComponent>,
        public snackBarService: SnackBarService,
        private analyticsNotifierService: AnalyticsNotifierService
    ) {
        this.plansService.paymentNotifier$.pipe(takeUntil(this.onDestroy$)).subscribe((paymentStatus) => {
            // We should get a truthful result when payment done.
            // Todo: get a payment token and test it or add a guard to test it.
            if (paymentStatus && dialogRef) {
                console.log('Closing the payment dialog');
                /// Check if the dialog is open
                if (dialogRef.getState() === MatDialogState.OPEN) {
                    // Close the payment dialog.
                    dialogRef.close(true);
                } else {
                    console.warn('The dialog is not open, WTF?!');
                }
            }
        });

        plansService.unifiedProduct$.pipe(takeUntil(this.onDestroy$)).subscribe((unifiedProduct) => {
            if (!unifiedProduct) return;
            // console.log('New unified product: ', unifiedProduct);

            this.unifiedProduct = unifiedProduct;

            const couponResponse = unifiedProduct?.productConfigs?.couponResponse;
            this.appliedCoupon = couponResponse?.appliedCoupon;
            this.finishedLoadingCoupon$.next();
            if (this.appliedCoupon) {
                this.snackBarService.openMessage(this.appliedCoupon?.promoCode + ' Coupon applied 🥳', null, 5000);
            }
            // Show snackbar on invalid coupon
            else if (couponResponse?.error) {
                plansService.wipePromoCodeFromLocalStorage();
                this.snackBarService.openMessage('This coupon is not valid 😢', null, 5000);
            }
        });

        plansService.getUnifiedPlan();
    }

    ngOnInit(): void {
        this.analyticsNotifierService.notifyEvent(`Payment Dialogue Opened`);
    }

    ngOnDestroy(): void {
        this.onDestroy$.next(true);
        this.onDestroy$.complete();
    }
}
